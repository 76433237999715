import { NotFoundError, UnauthorizedError } from './fetch_api'

type APIErrorHandler = (error: Error, isAdmin: boolean) => boolean
type APIErrorHandlers = {
  Unauthorized: APIErrorHandler
}

export const APIErrorHandlers = {
  Unauthorized: apiUnauthorizedErrorHandler,
}

// エラーであれば true を返す
function apiUnauthorizedErrorHandler(error: Error, isAdmin: boolean): boolean {
  if (error instanceof UnauthorizedError) {
    const path = encodeURIComponent(location.pathname)
    const loginPath = isAdmin
      ? `/admin/login?redirect_to=${path}`
      : `/login?redirect_to=${path}`
    window.location.href = loginPath
    return true
  }
  return false
}

// エラーであれば true を返す
export function handleAPIError(
  error: Error,
  handlers: APIErrorHandler[] = [],
  isAdmin = false,
): boolean {
  for (const handler of handlers) {
    if (handler(error, isAdmin)) return true
  }
  return false
}
